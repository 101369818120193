@import './custom-colors.sass'

.SingleDatePicker
  width: 200px
  .SingleDatePicker_picker
    margin-top: -20px
    z-index: 2
  .DateInput_input
    width: 200px
  &::after
    content: url(../assets/images/icons/calendar.png)
    position: absolute
    right: 10px
    top: 6px

.DateRangePicker
  width: 100%
  .DateRangePickerInput
    background-color: transparent
    border: none
    width: 100%
  .DateRangePickerInput_arrow
    visibility: hidden
  .DateInput
    background-color: transparent
    max-width: 220px
    width: 100%
    &:first-child::before,&:last-child::before
      background-color: transparent
      display: block
      font-size: 15px
      margin-bottom: 0.25rem
      padding-left: 3px
    &:first-child::before
      content: 'Check In'
    &:last-child::before
      content: 'Check Out'

.DateRangePicker,.SingleDatePicker
  .DateInput_input
    border: 1px solid $borderInputDate
    border-radius: 5px
    font-size: 15px
    font-weight: 400
    height: 36px
    padding: 6px 12px
  .CalendarMonth_caption
    font-size: 15px
    font-weight: 600
  .CalendarDay
    background-color: $salmonLight
    border: none !important
    color: $body-color
    font-size: 14px
    font-weight: 600
    outline: none !important
    transform: scale(0.9)
  .DateInput_fang
    display: none
  .CalendarDay.CalendarDay__blocked_out_of_range
    background: repeating-linear-gradient(-45deg, white, white 2px, rgba(100, 100, 100, 0.1) 2px, rgba(100, 100, 100, 0.1) 4px)
    color: $gray
    font-weight: 600
  .CalendarDay.CalendarDay__selected, .CalendarDay.CalendarDay__selected_span
    background-color: $salmon
    color: $body-color
    &:active, &:hover
      background: $salmon

// testing
.CalendarMonth_table
  .CalendarDay__selected_start, .CalendarDay__selected_end
    background: $salmonLight
  .CalendarDay__selected_start, .CalendarDay__selected_start:hover
    background: linear-gradient(to bottom right, $salmonLight 50%, $salmon 50%) !important
  .CalendarDay__selected_end, .CalendarDay__selected_end:hover
    background: linear-gradient(to bottom right, $salmon 50%, $salmonLight 50%) !important

.form-style
  .DateRangePicker
    .DateInput
      border: 1px solid $lgray
      border-radius: 35px
      padding: 0.4rem 1rem
      max-width: 100%
      > input
        border: none
        padding: 5px 25px 5px 0
        width: 100%
      &:first-child::before
        content: unset
      &:last-child::before
        content: unset
  &.column-2
    .DateRangePicker
      .DateRangePickerInput
        display: flex
        justify-content: space-around
      .DateInput
        max-width: 48%
