@import './custom-colors.sass'

.public-home
  margin-top: -1px
  .benefits
    margin-top: -25px
    h2
      font-size: 2.0625rem
      font-weight: 300
      margin: 0 auto
      max-width: 730px
      text-align: center
      width: 96%
    @media (max-width: 800px)
      margin-top: 0
    @media (max-width: 700px)
      padding-top: 1rem
      h2
        font-size: 1.8rem
        padding-top: 10px
    @media (max-width: 500px)
      h2
        font-size: 1.5rem
        width: 100%
  .charts
    .people,.wedding
      align-items: center
      display: flex
      flex-direction: column
      justify-content: flex-start
      min-height: 580px
      padding-top: 1.5rem
      text-align: center
      h2
        font-weight: 300
        max-width: 620px
        width: 90%
      p
        font-weight: 400
        font-size: 1rem
        margin: 0 auto
        max-width: 510px
        width: 90%
    .people
      background-color: $body-bg
      color: $body-color
    .wedding
      background-color: $body-color
      color: white
    @media (max-width: 1300px)
      .people,.wedding
        min-height: 600px
    @media (max-width: 575px)
      .people,.wedding
        min-height: unset
        padding: 1rem 0
  .general-info
    align-items: center
    color: $body-color
    display: flex
    justify-content: space-between
    button
      border-radius: 35px
      padding: 10px 0
    h2
      font-size: 2.1rem
      font-weight: 700
    img
      max-width: 780px
    p
      font-size: 1.3rem
      font-weight: 300
    .info
      max-width: 520px
      width: 90%
    @media (max-width: 1300px)
      img
        max-width: 720px
      .info
        max-width: 450px
    @media (max-width: 1200px)
      .info
        padding-left: 5px
    @media (max-width: 1000px)
      img
        max-width: 500px
      .info
        max-width: 400px
    @media (max-width: 850px)
      flex-direction: column
      img
        align-self: flex-end
        max-width: 100%
        order: 2
        width: 100%
        &.w99
          max-width: 99%
          width: 99%
      .info
        margin-bottom: 2rem !important
        max-width: 90%
        order: 1
        padding: 1rem 0
        text-align: center
  .line
    background-color: $lgray
    display: block
    height: 1px
    margin: 30px auto 0 auto
    width: 150px
  @media (max-width: 1300px)
    margin-top: -10px

.marker-content
  padding-left: 5px
  .marker-title
    font-size: 1.5rem
    font-weight: 700
    margin-bottom: 0.5rem
  .marker-desc
    font-size: 1.4rem
    font-weight: 500
    padding: 0.5rem 0
  .marker-image
    display: block
    img
      max-width: 100%
