@keyframes ldio-ciwyonw5jgs {
   0% { transform: translate(6px,40px) scale(0); }
  25% { transform: translate(6px,40px) scale(0); }
  50% { transform: translate(6px,40px) scale(1); }
  75% { transform: translate(40px,40px) scale(1); }
 100% { transform: translate(74px,40px) scale(1); }
}
@keyframes ldio-ciwyonw5jgs-r {
   0% { transform: translate(74px,40px) scale(1); }
 100% { transform: translate(74px,40px) scale(0); }
}
@keyframes ldio-ciwyonw5jgs-c {
   0% { background: #ff6e78 }
  25% { background: #ffd3d6 }
  50% { background: #ff6e78 }
  75% { background: #ffd3d6 }
 100% { background: #ff6e78 }
}
.ldio-ciwyonw5jgs div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(40px,40px) scale(1);
  background: #ff6e78;
  animation: ldio-ciwyonw5jgs 2.0833333333333335s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio-ciwyonw5jgs div:nth-child(1) {
  background: #ffd3d6;
  transform: translate(74px,40px) scale(1);
  animation: ldio-ciwyonw5jgs-r 0.5208333333333334s infinite cubic-bezier(0,0.5,0.5,1), ldio-ciwyonw5jgs-c 2.0833333333333335s infinite step-start;
}.ldio-ciwyonw5jgs div:nth-child(2) {
  animation-delay: -0.5208333333333334s;
  background: #ff6e78;
}.ldio-ciwyonw5jgs div:nth-child(3) {
  animation-delay: -1.0416666666666667s;
  background: #ffd3d6;
}.ldio-ciwyonw5jgs div:nth-child(4) {
  animation-delay: -1.5625s;
  background: #ff6e78;
}.ldio-ciwyonw5jgs div:nth-child(5) {
  animation-delay: -2.0833333333333335s;
  background: #ffd3d6;
}
.loadingio-spinner-ellipsis-r5h2kr9eeo {
  width: 90px;
  height: 90px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-ciwyonw5jgs {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.9);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio-ciwyonw5jgs div { box-sizing: content-box; }

/* SMALL */
.lds-default {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}
.lds-default div {
  position: absolute;
  width: 2px;
  height: 2px;
  background: #FF6E78;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: calc(37px * 0.375);
  left: calc(66px * 0.375);
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: calc(22px * 0.375);
  left: calc(62px * 0.375);
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: calc(11px * 0.375);
  left: calc(52px * 0.375);
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: calc(7px * 0.375);
  left: calc(37px * 0.375);
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: calc(11px * 0.375);
  left: calc(22px * 0.375);
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: calc(22px * 0.375);
  left: calc(11px * 0.375);
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: calc(37px * 0.375);
  left: calc(7px * 0.375);
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: calc(52px * 0.375);
  left: calc(11px * 0.375);
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: calc(62px * 0.375);
  left: calc(22px * 0.375);
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: calc(66px * 0.375);
  left: calc(37px * 0.375);
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: calc(62px * 0.375);
  left: calc(52px * 0.375);
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: calc(52px * 0.375);
  left: calc(62px * 0.375);
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

