.vimeo-card-blog {
  height: 686px;
  margin-left: auto;
  margin-right: auto;
  @media (width <= 768px) {
    height: auto;
    video {
      height: auto !important;
      min-height: 200px;
    }
  }
}