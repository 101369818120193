@import './custom-colors.sass'

.cover,.expanded-cover,.expanded-cover-gallery
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  border-radius: 0
  position: relative
  width: 100%

.eventcard
  background-color: white
  border: 1px solid $borderCards
  border-radius: 5px
  margin: 0 20px 20px 0
  max-width: 390px
  min-height: 386px
  padding: 0 0 20px 0
  width: 96%
  @media (max-width: 860px)
    margin: 0 0 15px 0
    width: 100%
  h1
    padding: 10px 0 7px 10px
  p
    display: block
    margin: 5px auto
    width: 90%
    &.eventcard__starting
      color: $body-color
      font-size: 18px
      span
        font-weight: 700
  .capacity-title
    color: $gray
    font-size: 16px
    font-weight: 600
    span
      color: $body-color
      font-size: 14px
      font-weight: 400
      padding: 0 10px
  .small-event-card
    display: flex
    flex-direction: column
    & .eventcard__rules
      border-top: 1px solid $lineGray
      border-bottom: none
      padding: 10px 0 0 0 !important
  &__cover
    height: 220px
    margin: 0 0 10px 0
    span
      align-items: center
      background-color: $salmon
      border-radius: 50%
      box-shadow: 0 2px 4px 0 $lineGray
      bottom: -30px
      display: flex
      height: 60px
      justify-content: center
      position: absolute
      right: 12px
      width: 60px
  &__data
    margin: 15px auto 0 auto
    padding: 10px 0 0 0
    width: 90%
    h3
      color: $gray
      font-size: 16px
      font-weight: 600
    ul
      list-style: none
      margin-bottom: 10px
      padding: 0 0 0 15px
      li
        padding: 0 0 12px 0
  &__booking
    align-items: center
    display: flex
    flex-wrap: wrap
    padding: 10px 0 0 0
    .input-capacity
      border: 1px solid rgba(0,0,0,0.2)
      border-radius: 5px
      display: block
      margin: 0 10px 0 0
      padding: 6px 10px 7px 10px
      width: 80px
    select
      margin: 0 0 10px 0
      max-width: 292px
      &:focus
        outline: none
        box-shadow: none
    .update-button:hover
      svg
        color: white !important
        transition: color 0.15s ease-in-out

.eventcard__rules
  border-bottom: 1px solid $lineGray
  display: flex
  justify-content: space-between
  margin: 0 auto
  padding-bottom: 10px
  width: 90%

.offering-modal.show
  .eventcard__data, .eventcard__rules
    width: 100% !important
  .eventcard__rules
    border-top: 1px solid $lineGray
    border-bottom: none
    padding: 10px 0 !important
