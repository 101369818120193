$body-bg: white
$body-color: #4a4a4a
$card-cap-bg: #F7F7F8
$gold: #dfd5c9
$brown: #56483A

$mediumGray: #a4a4a5
$lineGray: #c3c3c3
$gray: #858484
$salmon: #FF6E78
$salmonLight: #ffd3d6
$lgray: #dbdbdb
$bg-gray: #f7f7f8
$redError: #d50000
$borderInputDate: rgba(164,164,165,0.70)
$borderCards: rgba(219,219,219,0.80)
$green: #1CD589
