.slider-multiple-knt
  overflow: hidden
  .slide
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    height: 160px
    position: relative
  .slick-arrow.slick-prev,.slick-arrow.slick-next
    background-color: white
    height: 40px
    width: 35px
    z-index: 1
  .slick-arrow.slick-prev
    border-radius: 0 3px 3px 0
    left: 0 !important
  .slick-arrow.slick-next
    border-radius: 3px 0 0 3px
    right: 0 !important
