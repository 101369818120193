@import './custom-colors.sass'

.about-page
  header
    background-image: url('../assets/images/about-header.jpg')
    background-repeat: no-repeat
    background-size: cover
    @media (max-width: 450px)
      background-position: -120px 0
  .mission-section
    background-color: $bg-gray
