.slider-knt
  overflow: hidden
  .slide
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    height: 600px
    position: relative
    &.small
      height: 360px
  .slick-slide:not(.slick-current) .slide:after
    background-color: rgba(74, 74, 74, 0.45)
    content: ''
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%
  .slick-arrow.slick-prev,.slick-arrow.slick-next
    height: unset
    width: unset
    z-index: 1
  .slick-arrow.slick-prev
    left: 0 !important
  .slick-arrow.slick-next
    right: 0 !important
  @media (max-width: 650px)
    .slide
      height: 360px
    .slick-arrow.slick-prev
      left: -15px !important
    .slick-arrow.slick-next
      right: -15px !important
    .slick-arrow.slick-prev,.slick-arrow.slick-next
      height: 65px
      width: 65px
