@import './styles/custom-colors.sass'

$yiq-contrasted-threshold: 160
$theme-colors: ('primary': $salmon, 'gold': $gold, 'brown': $brown)
$font-size-base: 0.875rem
$font-family-sans-serif: 'Open Sans', 'Helvetica'
$container-max-widths: (xl: 1300px)

// Buttons
$btn-border-radius: 19px
$btn-border-radius-sm: 15px
$btn-focus-box-shadow: 0

@import '~bootstrap/scss/bootstrap.scss'
@import '~react-international-phone/build/index.css'
@import '~rc-drawer/assets/index.css'
@import './styles/range.scss'

.grecaptcha-badge
  display: none !important
#root
  margin: 0 auto
  min-width: 360px
main
  padding-top: 60px
  @media ( max-width: 991px )
    padding-top: 57px

.public-container
  background-color: white
  margin: 0 auto
  max-width: 1440px
  overflow: hidden
  width: 100%
  &.transparent
    background-color: transparent

.nav-link.active
  border-bottom: 3px solid map-get($theme-colors, 'primary')

.outline-none
  outline: none !important

.reset-input-style
  border: 1px solid #ced4da !important
  box-shadow: unset !important
  outline: none !important

.justify-content-evenly
  justify-content: space-evenly !important

.input-outline-none
  &:focus
    border-color: rgb(206, 212, 218) !important
    box-shadow: none !important

.flex-center
  align-items: center
  display: flex
  justify-content: center
  &-column
    flex-direction: column
  &-start
    justify-content: flex-start

.addingNewTier
  height: 30px
  line-height: 30px
  input
    color: $body-color
    height: 28px
    max-width: 120px
    outline: none
  button
    height: 28px
    line-height: 20px
    padding: 0 10px 0 8px
.tier-item-editing
  border: 1px solid $salmon
  border-radius: $btn-border-radius
  color: $body-color
  display: inline-block
  font-family: $font-family-sans-serif
  font-weight: bold
  font-size: 13px
  height: 28px
  line-height: 25px
  padding: 0px 10px
  vertical-align: middle
  svg
    color: $body-color
  button
    background: transparent
    border: none
    outline: none
    padding: 0 2px
  form
    display: inline-block
  input
    color: $body-color
    height: 24px
    max-width: 100px
    outline: none
  &.error
    border-color: map-get($theme-colors, 'danger')
    box-shadow: 0px 0px 5px map-get($theme-colors, 'danger')
  &.editing
    border-color: $lgray
    svg
      color: $salmon

.w-fit-content
  width: fit-content !important

.no-under
  text-decoration: none !important
.cursor
  &:hover
    cursor: pointer
  &.under
    &:hover
      text-decoration: underline
.bradius-1
  border-radius: 3px

.componer-error-textarea
  .invalid-feedback
    font-size: 14px
    margin-bottom: -10px
    padding: 10px 0 0 0
    text-align: right

.swal2-styled.swal2-confirm
  border-left-color: $salmon !important
  border-right-color: $salmon !important

.fwl // font-weight-light
  font-weight: 300
.fwn // font-weight-normal
  font-weight: 400
.fwm // font-weight-medium
  font-weight: 500
.fwsb // font-weight-semibold
  font-weight: 600
.all-fwsb *
  font-weight: 600
.fwb // font-weight-bold
  font-weight: 700
.fweb // font-weight-extra-bold
  font-weight: 900
.fz-10
  font-size: 10px
.fz-11
  font-size: 11px
.fz-12
  font-size: 12px
.fz-13
  font-size: 13px
.fz-14
  font-size: 14px
.fz-15
  font-size: 15px
.fz-16
  font-size: 16px
.fz-18
  font-size: 18px
.fz-20
  font-size: 20px
.fz-24
  font-size: 24px
.fz-30
  font-size: 30px
.fz-35
  font-size: 35px
.fz-45
  font-size: 45px
.fz-50
  font-size: 50px
.fz-60
  font-size: 60px

@media ( max-width: 600px )
  .fz-sm-14
    font-size: 14px !important

.w-90
  width: 90%
.mw-1200
  max-width: 1200px !important
.mw-1300
  max-width: 1300px !important
.mw-1000
  max-width: 1000px !important
.mw-900
  max-width: 900px !important
.mw-800
  max-width: 800px !important
.mw-600
  max-width: 600px !important
.mw-500
  max-width: 500px !important

.hide-on-mobile
  @media ( max-width: 767px )
    display: none

.tc-gray // text color
  color: $body-color !important
.tc-lgray
  color: $gray
.tc-error
  color: $redError
  font-weight: 600

.btn-lgray
  background-color: $lgray
  border-color: $lgray
  color: $body-color
  font-weight: 600
.bg-lgray
  background-color: $bg-gray
.bg-salmon
  background-color: $salmon
.bg-salmon-l
  background-color: $salmonLight

.filter-button
  background-color: white
  border-color: $gray
  color: $gray
  display: inline-block
  height: 31px
  padding: 0
  width: 31px
  &:hover, &:active, &:focus
    background-color: white !important
    border-color: $gray !important
    color: $gray !important
.btn-outline-salmon-simple
  background-color: white
  border-color: $salmon
  color: $salmon
  &:hover, &:active, &:focus
    background-color: white !important
    border-color: $salmon !important
    color: $salmon !important
    box-shadow: none !important
.btn-outline-salmon
  background-color: white
  border-color: $salmon
  color: $salmon
.btn-outline-gray
  background-color: white
  border-color: $gray
  color: $gray
  &:hover
    background-color: $gray
    border-color: $gray
    color: white
.btn-salmon
  background-color: $salmon !important
  border-color: $salmon !important
  color: white
.badge-salmon
  background-color: $salmonLight !important
  border-color: $salmonLight !important
  color: #F9515D

.small-btn-outline
  background-color: white
  border: 1px solid $body-color
  border-radius: 20px
  font-size: 13px
  padding: 0.05rem 0.4rem 0.1rem 0.4rem
  transition: all 0.1s linear
  &:hover, &:active, &:focus
    outline: none
  &:active
    border: 1px solid $salmon
    color: $salmon
.small-btn-outline-gray
  background-color: white
  border: 1px solid $body-color
  border-radius: 20px
  color: $body-color
  font-size: 13px
  padding: 0.05rem 0.4rem 0.1rem 0.4rem
  transition: all 0.1s linear
  &:hover, &:active, &:focus
    outline: none
  &:active
    background-color: $body-color
    color: white
    transition: all 0.1s linear

.btn-gray,.badge-gray
  background-color: $lgray !important
  border-color: $lgray !important
  color: $body-color
  &:hover
    color: $body-color
.nav-item
  .nav-link:not(.active)
    color: $gray
.border-b
  border-bottom: 2px solid rgba(0, 0, 0, 0.14)
.border-b-1
  border-bottom: 1px solid rgba(0, 0, 0, 0.14)
.rounded-25-0-0-25
  border-radius: 0.25rem 0 0 0.25rem !important
.rounded-0-25-25-0
  border-radius: 0 0.25rem 0.25rem 0 !important

.line-1
  line-height: 1

.after-line::after
  content: ""
  display: block
  width: 100px
  height: 12px
  margin: 0px auto
  border-bottom: 1px solid rgb(164, 164, 165)

.custom-options
  background-color: transparent !important
  border: none
  *
    color: #4a4a4a
  &::after
    content: none
  &:focus
    box-shadow: unset !important
    display: flex !important
  &:active
    background-color: transparent !important

.custom-input-style-textarea
  border: 1px solid $lgray
  border-radius: 15px
  margin: 30px 0
  padding: 10px 15px
  position: relative
  label
    background-color: white
    left: 14px
    margin: 0
    padding: 0 5px
    position: absolute
    top: -12px
  textarea
    border: none
    height: auto
    padding: 0
    &:focus
      box-shadow: none
  .invalid-feedback
    bottom: -18px
    left: 14px
    margin: 0
    position: absolute

span.required
  color: $salmon
  padding: 0 3px

.custom-input-style-simple
  align-items: center
  border: none !important
  display: flex
  margin: 15px 0 !important
  padding: 0 !important
  label
    margin: 0
    font-weight: 600
    left: unset !important
    padding: 0 20px 0 0 !important
    position: relative !important
    top: unset !important
    span.required
      display: none
    .top-right-form
      right: -12px !important
  input
    border: 1px solid $lgray !important
    padding: 5px !important
    width: 50px !important

.custom-switch-knt
  .custom-control-input ~ .custom-control-label::before
    background-color: #858484
    border-color: #858484
    border-radius: 25px
    box-shadow: none !important
    color: white
    content: 'NO'
    padding: 0 0 22px 20px
    top: 0
    transition: all 0.15s ease-in-out
    transform: scale(0.75)
    width: 3.5rem
    transition: all 0.15s ease-in-out
  .custom-control-input:checked ~ .custom-control-label::before
    background-color: #1cd589
    border-color: #1cd589
    border-radius: 25px
    content: 'YES'
    padding: 0 0 22px 7px
    top: 0
    transition: all 0.15s ease-in-out
    transform: scale(0.75)
    width: 3.5rem
  .custom-control-input:checked ~ .custom-control-label::after
    border: 1px solid #1cd589
    border-radius: 25px
    color: #1cd589
    content: '✓'
    font-weight: bold
    left: -4px
    padding: 0 16px 21px 4px
    top: 1px
    transform: scale(0.85)
    transition: all 0.15s ease-in-out
  .custom-control-input ~ .custom-control-label::after
    background-color: $body-color
    border: 1px solid $body-color
    border-radius: 25px
    color: white
    content: 'X'
    font-weight: bold
    left: calc(-2.25rem + -1px)
    padding: 0 16px 21px 4px
    top: 1px
    transform: scale(0.8)
    transition: all 0.15s ease-in-out

.youtube-video-container-vertical
  margin: 0 auto
  width: fit-content

.youtube-video-container-horizontal
  width: 100%
  padding-top: 56.25%
  height: 0px
  position: relative
  .knt-youtube-video
    left: 0
    height: 100%
    position: absolute
    top: 0
    width: 100%
  @media ( max-width: 790px )
    height: auto
    padding-top: 0
    .knt-youtube-video
      position: relative

// Modals
.guest-list-modal
  .modal-dialog
    max-width: 400px
  .guest-list-modal-title
    background-color: $body-color
    color: white
    padding: 10px 1rem
    .close
      color: white
      margin: -0.9rem -1rem -1rem auto
.poll-modal
  .modal-dialog
    max-width: 400px
  .poll-modal-title
    background-color: $body-color
    color: white
    padding: 10px 1rem
    .close
      color: white
      margin: -0.9rem -1rem -1rem auto
  .poll-modal-content
    div
      align-items: center
      display: flex
      font-weight: 600
      justify-content: flex-start
      padding: 6px 10px
      width: 100%
      .user-name
        margin-left: 10px

.scroll-box
  @media ( min-width: 992px )
    height: 500px
    overflow-y: scroll
    &::-webkit-scrollbar
      width: 5px
    &::-webkit-scrollbar-track
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1)
    &::-webkit-scrollbar-thumb
      background-color: $lineGray
      outline: none

.post-content
  display: flex
  flex-wrap: wrap
  justify-content: space-between

.image-block
  width: 100%
  &.blogImages-side-by-side
    width: 48%
    @media ( max-width: 600px )
      width: 100%

.composer-options
  margin: 0 0 0 5px
  padding: 4px 8px 6px 8px
  &::after
    content: none

.photo-details-vertical
  max-width: 650px
  width: 100%

.svg-top1
  position: relative
  top: -1px

.top-right
  position: absolute
  right: 0px
  top: 0px

.modal-rounded-0
  .modal-content
    border: none !important
    border-radius: 0 !important
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4)

.modal-fit-content
  .modal-dialog
    max-width: fit-content !important
    &.modal-dialog-centered
      margin-left: auto !important
      margin-right: auto !important

.flight-tabs-menu
  .add-flight
    align-items: center
    border-bottom: 3px solid transparent
    display: flex
    justify-content: center
    padding: 0 10px
    transition: border-color 0.2s ease-in-out
    user-select: none
    span
      display: block
      margin: 0
      padding: 7px 0
      width: 100%
    &:hover
      cursor: pointer
      border-color: $salmon
      transition: border-color 0.2s ease-in-out
.flight-tabs-container
  .tab-pane
    padding: 0 15px 20px 15px !important

// 1 more than drawer
.fade.modal-backdrop.show
  z-index: 10000
.modal.show
  z-index: 10000
.swal2-container.swal2-shown
  z-index: 10000

.modal-w-60
  .modal-dialog
    max-width: 60% !important
.modal-w-50
  .modal-dialog
    max-width: 50% !important
.modal-w-900
  .modal-dialog
    max-width: 900px !important
.modal-w-600
  .modal-dialog
    max-width: 600px !important

.modal-w-50, .modal-w-60
  .modal-dialog
    @media ( max-width: 768px )
      max-width: 100% !important

#select-myEvent-provider
  border: 1px solid rgba(164, 164, 165, 0.7)
  height: 36px !important
  &:focus
    box-shadow: unset
    outline: none

// lf = Lucky Fellas
.font-lf
  font-family: LuckyFellas, 'Open Sans', 'Helvetica'

.font-poppins
  font-family: "Poppins", serif
  font-style: normal

.font-playfair
  font-family: "Playfair Display", serif
  font-optical-sizing: auto

.edit-add-guest
  .simple-field-knt:not(.custom-input-style-simple)
    margin: 20px 0 40px 0 !important
.add-guest-close-icon
  left: -45px
  position: absolute
  top: -20px
  @media ( max-width: 767px )
    margin-top: -10px
    position: static

.no-focus
  &:focus
    border: 1px solid #ced4da !important
    box-shadow: unset !important
    outline: none !important

.rounded-10
  border-radius: 10px

#cart-detail-list
  max-width: 360px !important
  padding: 10px 10px 5px 10px
  width: 100% !important
  *
    background-color: white
  .popover-header
    border-bottom: none
    padding: 10px 5px
    *
      color: $body-color !important
  .popover-body
    padding: 0

.all-notifications
  background-color: white
  margin: 2rem auto
  max-width: 700px
  .notification:first-child
    border-top: none
  @media ( max-width: 700px )
    margin: 0 auto 1rem auto

.notification
  align-items: center
  border-top: 1px solid $lgray
  display: flex
  justify-content: space-between
  padding: 10px 0
  width: 100%
  .notification-body,.notification-body > a
    align-items: center
    display: flex
    justify-content: space-between
    padding-right: 10px
    > img
      border-radius: 50%
      height: 35px
      margin-right: 10px
      width: 35px
  .notification-body > a
    color: $body-color
    &:hover
      cursor: pointer
      text-decoration: none
  .notification-actions
    align-items: center
    display: flex
    justify-content: flex-end
    padding-right: 2px
    .seen
      background-color: $green !important
      border-radius: 50%
      display: block
      height: 10px
      width: 10px

#toast-container
  position: fixed
  right: 3%
  top: 50px
  z-index: 100
  div
    border: none
    position: relative
    width: 550px
    a
      font-size: 16px
    img
      height: 90px
      width: 90px
    .close-notification
      align-items: center
      background-color: lighten($body-color, 50%)
      border-radius: 50%
      display: flex
      font-weight: 600
      height: 18px
      justify-content: center
      position: absolute
      right: -5px
      top: -5px
      width: 18px
      &:hover
        cursor: pointer
  @media ( max-width: 550px )
    right: 1%
    width: 98%
    div
      width: 100%

// Public pages
.public-page
  header
    background-image: url('./assets/images/Partnership/hero_partnerships.jpg')
    background-repeat: no-repeat
    background-size: cover
    &.partnership-landing-page, &.ReferralStyle
      background-image: url('./assets/images/Partnership/new_hero_partnerships.jpg') !important
      .title, .subtile
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.4)
    .title
      margin-bottom: 10px
      position: relative
      &::after
        background: url('./assets/images/Partnership/handrawn-lines.png') no-repeat
        bottom: 0px
        content: ""
        display: block
        height: 33px
        position: absolute
        right: 15%
        width: 317px
    @media (max-width: 800px)
      background-position: -150px 0
      .title::after
        right: 5%
    @media (max-width: 730px)
      .title::after
        display: none
    @media (max-width: 600px)
      background-position: -350px 0

.tooltip.show
  z-index: 10001 // 2 more than drawer

// Panel
.main-social-panel
  @media (max-width: 767px)
    .hide-section
      display: none
    .display
      display: block

// event Components
.success-booked-feedComponentGroup
  background-color: #028702
  border-radius: 10px
  color: white
  padding: 0 15px
  user-select: none

// provider Details
.detailsPackages
  border: none
  .nav-item.nav-link
    border: none
    color: $gray
    font-weight: 600
    &.active
      border-bottom: 2px solid $salmon
      color: $salmon
    &:focus
      outline: none
.tab-pane.active.show
  padding: 2rem 5px

// offering-modal
.location-overview-container
  bottom: 0
  position: absolute
  left: 0
  width: 100%
  .panel-style
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.7) 40%, rgba(0, 0, 0, 0.4) 88%, transparent 100%)
    margin: 0 auto
    max-width: 100%
    padding: 12px 0
    position: relative
    width: 100%
    *
      color: white !important
    h4
      text-align: center
    .time
      display: none
    &.packages
      padding: 10px 0 0 0
      top: 5px !important
    &.rooms
      top: -5px !important
    @media (max-width: 991px)
      max-width: 648px
      width: 100%
    @media (max-width: 400px)
      .LocationOverview > div:first-child
        display: none

.expanded-cover-gallery
  height: 500px
.offering-modal
  .modal-title
    width: 100%
  .single-photo
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    height: 400px
    width: 100%
    &.modal-image
      position: relative
      .location-overview-container .panel-style
        top: 0px !important
        width: 100% !important
    @media (max-width: 600px)
      height: 200px
  .modal-dialog.modal-dialog-centered
    max-width: 750px
  &.expanded
    .modal-dialog.modal-dialog-centered
      max-width: 1000px !important
  .modal-header
    border-bottom: none
  .expanded-data
    margin: 0 auto 10px auto
    padding: 15px 0 0px 0
    h3, h2
      font-size: 16px
      font-weight: 600
      &.secondary
        color: $gray
        font-size: 14px
        padding: 0 0 0 10px
    ul
      columns: 2
      font-size: 13px

#event-menu-mobile
  text-align: left
  min-width: 170px
  &::after
    margin-left: 4.8em

// all sliders have the same color
.swiper-button-prev, .swiper-button-next
  color: $salmon !important
.swiper-pagination.swiper-pagination-bullets
  .swiper-pagination-bullet-active
    background: $salmon !important

.swiper-booked-EC-slider
  .swiper-button-prev, .swiper-button-next
    &::after
      transform: scale(0.5)

.swiper-rsvp-slider, .top-searches-slider
  .swiper-button-prev, .swiper-button-next
    background-color: white
    border-radius: 50%
    font-weight: bold
    height: 35px
    width: 35px
    &::after
      transform: scale(0.5)
  &.arrows-style2
    .swiper-button-prev, .swiper-button-next
      border-radius: 5px
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)
      height: 60px
      width: 40px
      &::after
        transform: scale(0.8)

.setting-card-helper
  padding-top: 45px
  @media (max-width: 767px)
    padding-top: 0

.timeline-helper
  &.bg-image
    border-radius: 7px
    & + .b-arrow
      border-top-color: rgba(0, 0, 0, 0.4) !important
  &.overlay
    align-items: center
    background-color: rgba(0, 0, 0, 0.5)
    border-radius: 5px
    display: flex
    flex-direction: column
    justify-content: center
    color: white
    height: 130px

.mw-landing-img
  display: block
  margin: 0 auto
  max-width: 320px

.invitation-preview-container
  margin-top: -90px
  @media (max-width: 1300px)
    margin-top: -140px

.paid-guests-form-table
  @media (max-width: 767px)
    tbody
      tr
        &:nth-child(1)
          display: flex
          justify-content: space-between
          td
            width: fit-content
        &:nth-child(2)
          td
            padding-top: 6px !important
            width: 100%
            label
              font-weight: 600
              margin-bottom: 4px
            &:nth-last-child(-n+2)
              width: fit-content
            &:nth-child(5)
              margin-left: auto
              div
                display: flex
                .form-label
                  padding: 0 8px 3px 0
                .custom-checkbox
                  margin: 0 !important
        &:nth-child(3)
          td
            text-align: center !important

.free-guests-form-table
  @media (max-width: 767px)
    tbody
      tr
        td
          &:nth-child(1)
            order: 1
          &:nth-child(2)
            order: 2
          &:nth-child(3)
            order: 3
          &:nth-child(4)
            order: 4
          &:nth-child(5)
            margin-left: auto
            order: 6
            .custom-checkbox
              display: inline-block !important
          &:nth-child(6)
            order: 7
          &:nth-child(7)
            order: 5
          &:nth-last-child(-n+3)
            width: fit-content

.paid-guests-table
  @media (max-width: 767px)
    tbody
      tr
        td
          &:nth-child(5)
            margin-right: auto
            .custom-checkbox
              display: inline-block !important
          &:nth-last-child(-n+2)
            width: fit-content

.free-guests-form-table, .paid-guests-form-table, .paid-guests-table
  @media (max-width: 767px)
    display: block
    margin: 0 auto
    max-width: 400px
    padding: 15px 10px
    width: 100%
    -webkit-overflow-scrolling: touch
    thead
      display: none
    tbody, th, td, tr
      display: block
      width: 100%
    tbody
      tr
        align-items: center
        display: flex
        flex-wrap: wrap
        justify-content: center

.international-phone-container
  margin-top: 32px
  &.label
    position: relative
    &::before
      background-color: white
      content: "Phone"
      font-size: 14px
      left: 20px
      padding: 0 6px
      position: absolute
      top: -13px
      z-index: 1
    &.required
      &::after
        background-color: white
        color: $salmon
        content: "*"
        left: 70px
        position: absolute
        top: -13px
        z-index: 1

.international-phone-input
  border-radius: 0 35px 35px 0 !important
  padding: 23px 10px !important
  width: 100% !important
.international-phone-button
  border-radius: 35px 0 0 35px !important
  padding: 23px 5px 23px 15px !important
