p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin: 0cm;
  line-height: 115%;
  font-size: 11.0pt;
}

.MsoPapDefault {
  line-height: 115%;
}

@page WordSection1 {
  size: 612.0pt 792.0pt;
  margin: 72.0pt 72.0pt 72.0pt 72.0pt;
}

div.WordSection1 {
  size: 612.0pt 792.0pt;
  margin: 22.0pt 72.0pt 72.0pt 72.0pt;
}

/* List Definitions */
ol {
  margin-bottom: 0cm;
}

ul {
  margin-bottom: 0cm;
}

html {
  scroll-behavior: smooth;
}