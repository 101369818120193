@import './custom-colors.sass'

.partnership-info
  button
    border-radius: 35px
    display: block
    font-size: 24px
    margin: 0 auto
    max-width: 450px
    padding: 10px 0
    width: 90%
  .max-1100
    margin: 20px auto
    max-width: 1100px
    width: 100%
    @media (max-width: 600px)
      padding: 0 15px
  .partnerships-list
    color: $gray
    display: flex
    flex-wrap: wrap
    font-size: 18px
    font-weight: 600
    margin: 0 auto
    max-width: 1100px
    width: 90%
    &__cta
      margin-top: 25px
      text-align: center
      width: 100%
      button
        border-radius: 30px
        font-size: 24px
        font-weight: 700
        padding: 8px 110px
      p
        font-size: 14px
        font-weight: 400
        margin: 5px auto
    &__item
      align-items: center
      display: flex
      margin: 25px 0
      width: 50%
      span
        display: block
        margin-left: 10px
        max-width: 370px
        width: 75%
    @media (max-width: 600px)
      .partnerships-list__item
        font-size: 16px
        margin: 5px 0
        width: 100%
  @media (max-width: 600px)
    .fz-sm-25
      font-size: 25px !important
